import React from 'react'
import Footer from '../components/Footer'

export default ({ children }) => {
  return (
    <div className='app-wrapper'>
      <main>
        {children}
      </main>
        <Footer/>
    </div>
  )
}
