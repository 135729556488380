import React from "react"
import Helmet from 'react-helmet'
import { Router } from '@reach/router'
import Layout from '../layouts'
import Order from '../components/account/order'

export default function Admin() {
  return (
      <Layout>
        <Helmet>
          <title>Account | Signxperts</title>
        </Helmet>
        <Router>
          <Order path="/account/orders" />
        </Router>
      </Layout>
  )
}
