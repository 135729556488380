import React, {useEffect, useState} from "react"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import {formatCurrencyString} from '../../context'
import 'moment-timezone'

import Header from "../Header"
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormLabel from "@material-ui/core/FormLabel";
import queryString from "query-string";
import Alert from "@material-ui/lab/Alert";
import CardSection from "../account/CardSection";
import { Elements } from '@stripe/react-stripe-js'
import {loadStripe} from "@stripe/stripe-js"

const stripe = loadStripe(process.env.GATSBY_STRIPE_CLIENT_KEY)

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        },
        width: '100%'
    },
    input: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        color: '#525f7f',
        fontSize: 16,
        marginBottom: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    }
}))(InputBase)

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 100,
        width: 500,
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%'
        },
        '& .MuiFormGroup-row': {
            alignItems: 'center'
        },
        '& .MuiFormControl-root': {
            backgroundColor: '#fff',
            border: 'none',
            borderRadius: 4,
            boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
            display: 'block',
            marginBottom: 20
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: 'rgba(0, 0, 0, 0.54)'
            }
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    bottom: {
        color: '#999',
        animationDuration: '550ms',
        marginLeft: 10
    },
    gridItem: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 50
    },
    lineItem: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    }
}))

const Order = ({ id, location }) => {
    const classes = useStyles()
    const [state, setState] = useState({
        orderNumber: '',
        phone: '',
        error: null,
        loading: true,
        isValid: false,
        toggle: {
            shippingCarrier: false,
            trackingNumber: false
        }
    })
    const {requestApproval} = queryString.parse(location.search)

    useEffect(() => {
        console.log(requestApproval)
        if (requestApproval === 'reject') {
            cancelOrder();
        }
    }, [])

    const cancelOrder = async () => {
        try {
            const payload = {
                orderNumber: state.orderNumber,
                phone: state.phone,
                requestApproval
            }

            const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-account-update-order`, {
                method: 'POST',
                body: JSON.stringify(payload)
            });
            const results = await response.json();
            setState({...state, ...results, error: null, isValid: true, loading: false});
        } catch (e) {
            console.log(e)
            setState({...state, error: 'This order doesn\'t exist', loading: false});
        }
    }

    const getOrder = async () => {
        try {
            const payload = {
                orderNumber: state.orderNumber,
                phone: state.phone
            }

            const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-account-get-order`, {
                method: 'POST',
                body: JSON.stringify(payload)
            });
            const results = await response.json();
            setState({...state, ...results, error: null, isValid: true, loading: false});
        } catch (e) {
            console.log(e)
            setState({...state, error: 'This order doesn\'t exist', loading: false});
        }
    }

    const handleUpdate = event => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    /*
    const handleOrder = async (event) => {
        event.preventDefault()
        console.log(state)
        // Recalculate total
        const total = state.order.subTotal + state.order.salesTax + parseInt(state.update.shippingAmount.replace('.', ''));
        if (state.update) {
            try {
                let payload = {
                    id,
                    ...state.update
                }
                if (state.update.hasOwnProperty('shippingAmount')) payload = {...payload, shippingAmount: parseInt(state.update.shippingAmount.replace('.', '')), total}

                const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/api-admin-update-order`, {
                    method: 'POST',
                    body: JSON.stringify(payload)
                });
                const results = await response.json();
                setState({
                    ...state,
                    order: {
                        ...results.updateOrder
                    },
                    toggle: {
                        ...state.toggle,
                        shippingMethod: false,
                        trackingNumber: false
                    }
                });
            } catch (e) {
                console.log(e)
                setState({
                    ...state,
                    toggle: {
                        ...state.toggle,
                        shippingCarrier: false,
                        trackingNumber: false
                    }
                })
            }
        }
    }
    */

    const handleSubmit = event => {
        event.preventDefault()
        if (state.orderNumber === '' && state.phone === '') {
            setState({...state, error: true});
        } else {
            getOrder();
        }
    }

    return (
        <>
            <Header/>
            {!state.isValid && (
                <div className={classes.root}>
                    <Typography variant={'h4'} gutterBottom>Account</Typography>
                    <form
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <Box>
                            <FormLabel>
                                <BootstrapInput type='text' name='orderNumber' onChange={handleUpdate} placeholder='Order Number' required />
                            </FormLabel>
                            <FormLabel>
                                <BootstrapInput type='text' name='phone' onChange={handleUpdate} placeholder='Phone' required />
                            </FormLabel>
                        </Box>
                        {state.error && (
                            <Box marginBottom={5}>
                                <Alert variant="filled" severity="error">
                                    Incorrect order number and/or phone number.
                                </Alert>
                            </Box>
                        )}
                        <Box marginBottom={5} align={'right'}>
                            <Button type={'submit'} variant={'contained'} color={'secondary'}>
                                Submit
                            </Button>
                        </Box>
                    </form>
                </div>
            )}
            {state.isValid && (
                <Container maxWidth={'md'}>
                    <Grid container>
                        <Grid item className={classes.gridItem}>
                            <Typography variant={'h4'} gutterBottom>Order</Typography>
                        </Grid>
                    </Grid>
                {state.loading && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.bottom}
                            size={20}
                            thickness={4}
                        />
                    </Box>
                )}
                {!state.loading && (
                    <Grid container>
                        <Grid item sm={6} className={classes.gridItem}>
                            <Elements stripe={stripe}>
                                <CardSection data={{...state.order, orderNumber: state.orderNumber}} />
                            </Elements>
                        </Grid>
                        <Grid item sm={6} className={classes.gridItem}>
                            <Box marginBottom={5}>
                                <Typography variant={'h5'} align={'center'}>Order Summary</Typography>
                            </Box>
                            <Box marginBottom={3}>
                                {state.order.orderItems.map((item, index) => (
                                    <Box key={index} marginBottom={2}>
                                        <Grid container alignItems={'center'}>
                                            <Grid item sm={3}>
                                                <img className='image' src={item.image} alt={item.name} width={'100%'} style={{marginRight: 10}} />
                                            </Grid>
                                            <Grid item sm={5}>
                                                <Typography variant={'h6'}>{item.name}</Typography>
                                                {Object.entries(item.metadata).map(([key, item], index) => (
                                                    key !== 'files' && key !== 'parcel' && (
                                                        <Box key={index} alignItems={'center'} display={'flex'}>
                                                            <Typography style={{marginRight: 10}}>{key}:</Typography>
                                                            <Typography>{item}</Typography>
                                                        </Box>
                                                    )
                                                ))}
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography align={'right'}>Qty: {item.quantity}</Typography>
                                            </Grid>
                                            <Grid item sm={2}>
                                                {/*<Typography align={'right'}>${((item.quantity * item.price)/100).toFixed(2)}</Typography>*/}
                                                <Typography align={'right'}>
                                                    {formatCurrencyString({
                                                        value: item.quantity * item.price,
                                                        currency: 'USD',
                                                        language: 'en-US'
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                            <Box marginBottom={3}>
                                <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
                            </Box>
                            <Box marginBottom={3}>
                                <Box className={classes.lineItem}>
                                    <Typography>Subtotal</Typography>
                                    <Typography>
                                        {formatCurrencyString({
                                            value: state.order.total,
                                            currency: 'USD',
                                            language: 'en-US'
                                        })}
                                    </Typography>
                                </Box>
                                <Box className={classes.lineItem}>
                                    <Typography>Shipping</Typography>
                                    {state.order.shippingMethod && <Typography>
                                        {formatCurrencyString({
                                            value: state.order.shippingAmount,
                                            currency: 'USD',
                                            language: 'en-US'
                                        })}
                                    </Typography>}
                                    {!state.order.shippingMethod && <Typography variant={'caption'}>Calculated at next step</Typography>}
                                </Box>
                                <Box className={classes.lineItem}>
                                    <Typography>Taxes {/(Connecticut|CT)/ig.test(state.order.billingAddress.state) && '(Connecticut 6.35%)'}</Typography>
                                    {state.order.billingAddress.state && (
                                        <Typography>
                                            {formatCurrencyString({
                                                value: state.order.salesTax,
                                                currency: 'USD',
                                                language: 'en-US'
                                            })}
                                        </Typography>
                                    )}
                                    {!state.order.billingAddress.state && <Typography variant={'caption'}>Calculated at next step</Typography>}
                                </Box>
                            </Box>
                            <Box marginBottom={3}>
                                <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
                            </Box>
                            <div className={classes.lineItem}>
                                <Typography>Total</Typography>
                                <Typography>
                                    {formatCurrencyString({
                                        value: state.order.total,
                                        currency: 'USD',
                                        language: 'en-US'
                                    })}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Container>
            )}
        </>
    )
}


export default Order
