import React, {Fragment, useEffect, useState} from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Box from "@material-ui/core/Box"
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import {navigate} from "gatsby"
import {useShoppingCart} from "../../context";
import { css } from 'styled-components'
import { Form, Field } from 'react-final-form'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import routingNumberList from '../../services/routing-number-list'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      backgroundColor: '#fff',
      border: 'none',
      borderRadius: 4,
      boxShadow: '0 1px 3px 0 rgba(50, 50, 93, 0.15)',
      display: 'block',
      marginBottom: 20
    }
  },
  lineItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  bottom: {
    color: '#999',
    animationDuration: '550ms',
    marginRight: 10
  }
}))

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: 'sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF'
      }
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238'
      }
    }
  }
}

const accountTypes = {
  CHECKING: 'Checking',
  SAVINGS: 'Saving',
  BUSINESSCHECKING: 'Business Checking'
}

const required = value => (value ? undefined : 'Required')
const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
const minValue = min => value =>
    isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const maxValue = max => value =>
    isNaN(value) || value <= max ? undefined : `Should be greater than ${max}`
const accountNumberValue = value => {
  return isNaN(value) || value.length >= 4 && value.length <= 17 ? undefined : `${value} is invalid`
}
const allowedRoutingNumbers = routingNumberList.reduce((result, number) => {
  result[number] = true;
  return result;
}, Object.create(null));
const routingNumberValue = value => isNaN(value) || value in allowedRoutingNumbers ? undefined : `${value} is invalid`
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(.5)
    },
    width: '100%'
  },
  input: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: '#525f7f',
    fontSize: 16,
    marginBottom: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
}))(InputBase)

const BootstrapSelect = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(.5)
    },
    marginBottom: 16,
    width: '100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const TextFieldAdapter = ({ input, meta, ...rest }) => (
    <BootstrapInput
        {...input}
        {...rest}
        onChange={input.onChange}
    />
)

const ReactSelectAdapter = ({ input, ...rest }) => (
    <NativeSelect
        {...input}
        {...rest}
        onChange={input.onChange}
        input={<BootstrapSelect />}
    >
      {/*<option aria-label="None" value="" />*/}
      {Object.entries(rest.options).map(([value, label], index) => (
          <option key={label} value={value}>
            {label}
          </option>
      ))}
    </NativeSelect>
)

const CardSection = ({data}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    email: null,
    phone: null,
    total: 0,
    billingAddress: {
      name: undefined,
      street1: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      country: 'US'
    },
    orderItems: [{
      "name": "Mesh Banners",
      "image": "https://res.cloudinary.com/fitouch/image/upload/v1599675222/projects/corporate-banner_ygupow.png",
      "quantity": 10,
      "price": 2500,
      "metadata": {
        "color": "Red",
        "size": "10ft x 20ft",
        "files": ["https://res.cloudinary.com/fitouch/image/upload/v1599675222/projects/corporate-banner_ygupow.png", "https://res.cloudinary.com/fitouch/image/upload/v1589851565/projects/mp-smith-wesson-icon_r3aqlt.png"],
        "parcel": {
          length: "8",
          width: "8",
          height: "50",
          weight: "20",
          distance_unit: "in",
          mass_unit: "lb"
        }
      }
    }],
    shippingMethod: {
      "object_created": "2020-09-16T22:33:27.099Z",
      "object_id": "e80e06dfb34241f98349d6686abf78c7",
      "object_owner": "anthony.fabrizi@fitouch.com",
      "shipment": "6c81e1d78a274a57bd83904fe3efe359",
      "attributes": [],
      "amount": "32.19",
      "currency": "USD",
      "amount_local": "32.19",
      "currency_local": "USD",
      "provider": "UPS",
      "provider_image_75": "https://shippo-static.s3.amazonaws.com/providers/75/UPS.png",
      "provider_image_200": "https://shippo-static.s3.amazonaws.com/providers/200/UPS.png",
      "servicelevel": {
        "name": "3 Day Select®",
        "token": "ups_3_day_select",
        "terms": ""
      },
      "estimated_days": 3,
      "arrives_by": null,
      "duration_terms": "Delivery by the end of the third business day.",
      "messages": [],
      "carrier_account": "27e7019368e04c1f8637162dbe7cd7fa",
      "test": true,
      "zone": null,
      "source": "shippo"
    },
    error: null,
    loading: false,
    isCardComplete: false,
    isPaid: false
  })
  const {clearCart} = useShoppingCart()
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    wrapperProps
  } = usePaymentInputs()

  useEffect(() => {
    setState({...state, ...data})
  }, [])

  useEffect(() => {
    if (state.payment.creditCard || state.payment.bankAccount) {
      (async () => {
        setState({...state, loading: true})

        const payment = await payWithCard(state.id)
        if (payment.error) {
          // Show error to your customer
          showError(payment.error.message);
        } else {
          // The payment succeeded!
          // orderComplete(state.id, payment.paymentIntent.id);
          orderComplete(state.orderNumber, payment.paymentIntent.id);
        }
      })()
    }
  }, [state.payment])

  // Handle real-time validation errors from the card Element.
  const handleCard = (event) => {
    if (event.error) {
      setState({...state, error: event.error.message, isCardComplete: event.complete})
    } else {
      setState({...state, error: null, isCardComplete: event.complete})
    }
  }

  const payWithCard = async (orderId) => {
    // const card = elements.getElement(CardElement)
    const res = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/authorize-net-create-payment-intent`, {
      method: 'POST',
      body: JSON.stringify({
        email: state.email,
        phone: state.phone,
        payment: state.payment,
        currency: 'USD',
        amount: state.total,
        salesTax: state.salesTax,
        billingAddress: state.billingAddress,
        items: state.items,
        orderId
      })
    })
    const createPaymentIntent = await res.json();

    if (createPaymentIntent.messages.resultCode === 'Error') {
      // showError('Oops, something went wrong. Please try it again later.')
      return {
        error: {
          // message: createPaymentIntent.transactionResponse.errors.error[0].errorText
          message: 'Oops, something went wrong. Please try it again later.'
        }
      }
    } else {
      return {
        paymentIntent: {
          id: createPaymentIntent.transactionResponse.transId
        }
      }
    }
  }

  // const payWithStripeCard = async (orderId) => {
  //   const card = elements.getElement(CardElement)
  //   const res = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/stripe-create-payment-intent`, {
  //     method: 'POST',
  //     body: JSON.stringify({amount: state.total, items: state.orderItems, orderId})
  //   })
  //   const createPaymentIntent = await res.json();
  //
  //   if (createPaymentIntent.error) {
  //     showError(createPaymentIntent.error)
  //   } else {
  //     return await stripe.confirmCardPayment(createPaymentIntent.clientSecret, {
  //       payment_method: {
  //         card: card
  //       }
  //     })
  //   }
  // }

  // Shows a success message when the payment is complete
  const orderComplete = (orderNumber, paymentIntentId) => {
    setState({...state, orderNumber, isPaid: true})
    sessionStorage.removeItem('shippingMethods')
    sessionStorage.removeItem('order')
    clearCart()
    navigate(`/success?orderNumber=${orderNumber}`)
  }

  // Show the customer the error from Stripe if their card fails to charge
  const showError = (errorMsgText) => {
    setState({...state, error: errorMsgText, loading: false})
    setTimeout(() => {
      setState({...state, error: null, loading: false})
    }, 8000);
  };

  const onHandleSubmit = (data) => {
    let payment =
        state.paymentMethod === 'creditCard'
            ? {
              creditCard: {
                cardNumber: data.cardNumber.replaceAll(' ', ''),
                expiryDate: data.expiryDate.replaceAll(' ', ''),
                cvc: data.cvc
              }
            }
            : {
              bankAccount: {
                accountType: data.accountType,
                routingNumber: data.routingNumber.replaceAll(' ', ''),
                accountNumber: data.accountNumber.replaceAll(' ', ''),
                nameOnAccount: data.nameOnAccount
              }
            }

    setState({
      ...state,
      loading: true,
      payment
    })
  }

  const handlePaymentMethod = (event, paymentMethod) => {
    let obj = {}
    if (paymentMethod === 'pickup') {
      obj = {
        sameAsBillingAddress: false,
        deliveryAddress: {
          name: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          country: 'US'
        }
      }
    }
    setState({...state, ...obj, paymentMethod});
  };

  return (
    <div className={classes.root}>
      <Box marginBottom={5}>
        <FormControl>
          <Box className={classes.lineItem} style={{padding: 10}}>
            <Typography variant={'body1'}>Contact</Typography>
            <Typography variant={'body1'}>{state.email}</Typography>
            {/*
            <Link to="/checkout/contactInfo">
              <Typography variant={'body1'}>Change</Typography>
            </Link>
            */}
          </Box>
          <hr style={{borderColor: 'rgba(50, 50, 93, 0.05)'}}/>
          <Box className={classes.lineItem} style={{padding: 10}}>
            <Typography variant={'body1'}>Ship to</Typography>
            <Typography variant={'body1'}>{state.billingAddress.street1}, {state.billingAddress.city}, {state.billingAddress.state} {state.billingAddress.zip}</Typography>
            {/*
            <Link to="/checkout/contactInfo">
              <Typography variant={'body1'}>Change</Typography>
            </Link>
            */}
          </Box>
        </FormControl>
      </Box>
      <Box marginBottom={5}>
        <Typography variant={'h6'} gutterBottom>Payment Method</Typography>
        <ToggleButtonGroup
            size="small"
            value={state.paymentMethod}
            exclusive
            onChange={handlePaymentMethod}
            aria-label="Payment Method"
        >
          <ToggleButton disableRipple value="creditCard" aria-label="Credit Card">
            Credit Card
          </ToggleButton>
          <ToggleButton disableRipple value="eCheck" aria-label="eCheck">
            eCheck
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box marginBottom={5}>
        <Typography variant={'h6'} gutterBottom>Payment Information</Typography>
        {state.paymentMethod === 'creditCard' && (
            <Form
                initialValues={{
                  cardNumber: '',
                  expiryDate: '',
                  cvc: ''
                }}
                onSubmit={onHandleSubmit}
                validate={(values) => {
                  let errors = {};
                  if (meta.erroredInputs.cardNumber) {
                    errors.cardNumber = meta.erroredInputs.cardNumber;
                  }
                  if (meta.erroredInputs.expiryDate) {
                    errors.expiryDate = meta.erroredInputs.expiryDate;
                  }
                  if (meta.erroredInputs.cvc) {
                    errors.cvc = meta.erroredInputs.cvc;
                  }
                  return errors;
                }}
            >
              {props => {
                const {
                  handleSubmit,
                  invalid
                } = props;
                return (
                    <form onSubmit={handleSubmit}>
                      <Box marginBottom={5}>
                        <FormControl component='fieldset'>
                          <PaymentInputsWrapper
                              {...wrapperProps}
                              styles={{
                                fieldWrapper: {
                                  base: css`
                                    width: 100%;
                                    `
                                },
                                inputWrapper: {
                                  base: css`
                          border-color: transparent;`
                                },
                                input: {
                                  cardNumber: css`
                            width: 75%;
                          `,
                                  expiryDate: css`
                            width: 15%;
                          `,
                                  cvc: css`
                            width: 10%;
                          `
                                },
                              }}
                          >
                            <svg {...getCardImageProps({ images })} />
                            <Field name="cardNumber" validate={value => (value ? undefined : "Required")}>
                              {({ input }) => (
                                  <input {...getCardNumberProps({ onBlur: input.onBlur, onChange: input.onChange })} />
                              )}
                            </Field>
                            <Field name="expiryDate" validate={value => (value ? undefined : "Required")}>
                              {({ input }) => (
                                  <input {...getExpiryDateProps({ onBlur: input.onBlur, onChange: input.onChange })} />
                              )}
                            </Field>
                            <Field name="cvc" validate={value => (value ? undefined : "Required")}>
                              {({ input }) => <input {...getCVCProps({ onBlur: input.onBlur, onChange: input.onChange })} />}
                            </Field>
                          </PaymentInputsWrapper>
                        </FormControl>
                        {state.error && <Typography style={{color: 'red'}} gutterBottom>{state.error}</Typography>}
                      </Box>
                      <Box marginBottom={5} align={'right'}>
                        <Button type="submit" variant={'contained'} color={'primary'} disabled={state.loading || invalid}>
                          {!state.loading && 'Place Order'}
                          {state.loading && (
                              <Fragment>
                                <CircularProgress
                                    variant="indeterminate"
                                    disableShrink
                                    className={classes.bottom}
                                    size={20}
                                    thickness={4}
                                />
                                Processing...
                              </Fragment>
                          )}
                        </Button>
                      </Box>
                      {/*<DisplayFormikState {...props} />*/}
                    </form>
                )
              }}
            </Form>
        )}
        {state.paymentMethod === 'eCheck' && (
            <Form
                initialValues={{
                  accountType: 'CHECKING',
                  routingNumber: '',
                  accountNumber: '',
                  nameOnAccount: ''
                }}
                onSubmit={onHandleSubmit}
                render={({ handleSubmit, invalid, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Box marginBottom={5}>
                        <InputLabel shrink htmlFor="accountType-native-label-placeholder">
                          Account type
                        </InputLabel>
                        <Field
                            name="accountType"
                            inputProps={{
                              name: 'accountType',
                              id: 'accountType-native-label-placeholder',
                              autoComplete: 'off'
                            }}
                            component={ReactSelectAdapter}
                            validate={required}
                            options={accountTypes}
                        />
                        <InputLabel shrink htmlFor="routingNumber-native-label-placeholder">
                          Routing number
                        </InputLabel>
                        <Field
                            name="routingNumber"
                            inputProps={{
                              name: 'routingNumber',
                              id: 'accountType-native-label-placeholder',
                              autoComplete: 'off'
                            }}
                            component={TextFieldAdapter}
                            validate={composeValidators(required, routingNumberValue)}
                            placeholder="Routing Number"
                        />
                        <InputLabel shrink htmlFor="accountNumber-native-label-placeholder">
                          Account number
                        </InputLabel>
                        <Field
                            name="accountNumber"
                            inputProps={{
                              name: 'accountNumber',
                              id: 'accountNumber-native-label-placeholder',
                              autoComplete: 'off'
                            }}
                            component={TextFieldAdapter}
                            validate={composeValidators(required, accountNumberValue)}
                            placeholder="Account Number"
                        />
                        <InputLabel shrink htmlFor="nameOnAccount-native-label-placeholder">
                          Account holder's name
                        </InputLabel>
                        <Field
                            name="nameOnAccount"
                            inputProps={{
                              name: 'nameOnAccount',
                              id: 'nameOnAccount-native-label-placeholder',
                              autoComplete: 'off'
                            }}
                            component={TextFieldAdapter}
                            validate={composeValidators(required, maxValue(22))}
                            placeholder="First name Last name"
                        />
                      </Box>
                      <Box marginBottom={5} align={'right'}>
                        <Button type="submit" variant={'contained'} color={'primary'} disabled={state.loading || invalid}>
                          {!state.loading && 'Pay Now'}
                          {state.loading && (
                              <Fragment>
                                <CircularProgress
                                    variant="indeterminate"
                                    disableShrink
                                    className={classes.bottom}
                                    size={20}
                                    thickness={4}
                                />
                                Processing...
                              </Fragment>
                          )}
                        </Button>
                      </Box>
                      {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                    </form>
                )}
            />
        )}
      </Box>
    </div>
  )
}

export default CardSection
